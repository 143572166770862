<template>
    <div>
        <div class="login-page">
            <div class="login-form p-2">
                <div class="col-lg-6 col-lg-offset-3 col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 col-xs-12">
                    <div class="text-center">
                        <img class="transcrypt-logo-login-and-register mb-3 pointer"
                             src="/assets/remittance-assets/img/transcrypt-logo-colour.png"
                             @click="goToTradingHomepage()">
                    </div>
                    <div v-if="!forgotPassword">
                        <div class="text-center mb-2">
                            <h2 class="mb-2">
                                Welcome Back
                            </h2>
                            <span class="color-secondary">
                            New to TransCrypt ?
                            <router-link to="/trading/user/register" class="text-decoration-none">Sign up</router-link>
                        </span>
                        </div>

                        <div class="form-group">
                            <div class="input-group">
                                <input class="form-control" type="email" id="email-login" name="email" placeholder="Email"
                                       required=""
                                       v-model="emailLogin">
                            </div>
                            <p v-if="validationEmailLoginError" class="text-error"><small>Please enter your valid
                                registered email</small></p>
                        </div>

                        <div class="form-group">
                            <div class="input-group">
                                <input class="form-control" type="password" id="password-login" name="password"
                                       placeholder="Password"
                                       required="" data-error="Please enter your message subject"
                                       v-model="passwordLogin">
                            </div>
                            <p v-if="validationPasswordLoginError" class="text-error"><small>Please enter your
                                password</small></p>
                        </div>

                        <p class="text-center text-error mt-5" v-if="validationRegisteredEmailNotFound">
                            Email is not registered. Please enter the correct email.
                        </p>
                        <p class="text-center text-error mt-5" v-if="validationPasswordLoginNotAuthorized">
                           Password error. Please enter the correct password
                        </p>
                        <p class="text-center text-error mt-5" v-if="validationLoginError">
                            Login Error. Please enter the correct credentials.
                        </p>


                        <button @click="clickToLogin()" class="btn btn-success btn-block mb-2" id="login-button">
                           <span v-if="!loginSpinner">Login</span><span v-else>Logging in...</span>
                        </button>

                        <!-- TODO: partially remove-->

                        <!-- <div class="text-center">
                            <small> Forgot your password ? <a
                                    @click="switchToForgotPassword()" class="pointer text-decoration-none">
                                Click here </a></small>


                        </div> -->
                        <div v-show="getUserKyc === 'blocked'" :class="{'txAndRcvrStatus': getUserKyc === 'blocked'}">
                            <p class="p-1 m-0" style="text-align:center; display:grid">
                                <span class="p-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#c73b2d" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"></path>
                                    </svg>
                                    Oops! 
                                </span>To access your account please contact support at <strong class="text-success">support@transcryptglobal.com</strong>
                            </p>
                        </div>
                        <div v-show="getUserStatus ==='closed'">
                            <p class="p-1 m-0" style="text-align:center; display:grid">
                                <span class="p-0">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#c73b2d" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"></path>
                                    </svg>
                                    Oops! 
                                </span>Your account has been disabled. To access your account please contact support at <strong class="text-success">support@transcryptglobal.com</strong>
                            </p>
                        </div>
                    </div>
                    <div v-else>
                        <div v-if="!resetPasswordSuccessful">
                            <h3 class="text-center mb-5">Please enter your registered email</h3>
                            <div class="form-group mt-5 mb-5">
                                <div class="input-group">
                                    <input class="form-control" type="email" id="email-for-forgot-password" name="email" placeholder="Email"
                                           required=""
                                           data-error="Please enter your email"
                                           v-model="forgotPasswordEmail">
                                </div>
                                <p v-if="validationForgotPasswordEmailError" class="text-error"><small>Please enter your valid
                                    registered email</small></p>
                            </div>

                            <div class="text-center mt-5">
                                <div class="row">
                                    <div class="col-md-6 col-sm-12 col-xs-12 col-lg-6">
                                        <button class="btn btn-warning btn-block m-1" @click="backToLoginForm()">
                                            Back
                                        </button>
                                    </div>
                                    <div class="col-md-6 col-sm-12 col-xs-12 col-lg-6">
                                        <button class="btn btn-success btn-block m-1" @click="submitEmailToResetPassword()">
                                            Reset
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <br>
                            <br>
                            <br>
                            <h3 class="text-center">
                                A new password has been sent to your registered email.
                            </h3>
                            <h3 class="text-center">
                                Please check your inbox and use the new password to login to your account.
                            </h3>
                            <br>
                            <br>
                            <br>
                            <div class="text-center">
                                <button class="btn btn-success btn-clock" @click="backToLoginAfterResetPassword()">
                                    Back to Login
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
    import {mapActions, mapGetters} from 'vuex';

    export default {
        name: 'Login',
        data() {
            return {
                mode: process.env.VUE_APP_STAGING_ENV ? null : "production",
                emailLogin: "",
                passwordLogin: "",

                validationEmailLoginError: false,
                validationPasswordLoginError: false,
                validationLoginError: false,

                debugConsole : false,

                forgotPassword : false,
                forgotPasswordEmail : "",
                validationForgotPasswordEmailError : false,

                resetPasswordSuccessful : false,

                validationRegisteredEmailNotFound : false,
                validationPasswordLoginNotAuthorized : false,

                loginSpinner : false,

            }
        },
        watch: {
            $route() {

            }
        },
        mounted() {
            // window.addEventListener('keyup', this.pressEnterToLogin)
            this.$store.commit("UPDATE_USER_KYC");
            
            let url = new URL(window.location);
            if(url.pathname === '/trading/user/login');
            {
                document.addEventListener('keyup',this.pressEnterToLogin)

                //directly set the transfer type to "trading" when page loads
               this.submitTransferType('trading');
            }

        },
        beforeDestroy() {
            document.removeEventListener('keyup', this.pressEnterToLogin)
        },
        metaInfo: {
            title: 'TransCrypt - Login | Send Money Faster with Cryptocurrency',
        },
        computed : {
          ...mapGetters(['getUserKyc', 'getUserStatus'])
        },
        methods: {
            ...mapActions([
                "tradingUserLogin",
                "getReceivers", "getSenderDetails",
                "getTransactions",
                "resetPassword",
                "getUserBalances",
                "submitTransferType",
                "getAuthenteqUrl",
                "submitTransferType",
                "authLogout"
            ]),

            pressEnterToLogin(e){
                if(e.which === 13)
                {
                    this.loginSpinner = true;
                    setTimeout(() => {
                        this.goToMainPage()
                    },1000)
                }

            },
            clickToLogin(){
                this.loginSpinner = true;
                setTimeout(() => {
                    this.goToMainPage()
                },1000)
            },
            goToMainPage() {
                //validation
                if (!this.emailLogin || (this.emailLogin && this.emailLogin.indexOf('@') < 2)) {
                    this.validationEmailLoginError = true;
                } else {
                    this.validationEmailLoginError = false;
                }


                if (!this.passwordLogin) {
                    this.validationPasswordLoginError = true;
                } else {
                    this.validationPasswordLoginError = false;
                }

                if (this.validationEmailLoginError || this.validationPasswordLoginError) {
                    this.loginSpinner = false;
                    return;
                }

                //send data to backend
                let data = {};
                data.email = this.emailLogin;
                data.password = this.passwordLogin;

                if (data.email && data.password) {
                    //POST to API
                    this.tradingUserLogin(data).then(result => {
                          if(this.debugConsole) console.log("Result in Account login form", result);
                            if (result.data && result.data.jwt) {
                                this.validationLoginError = false;


                                let data = {}
                                data.email = this.emailLogin;
                                data.token = result.data.jwt;
                                data.userKey = result.data.user_key;
                                this.$store.commit("USER_LOGIN", data);

                                //get user balances (fiat and cryptos)
                              this.getUserBalances().then(result => {
                                if(this.debugConsole)  console.log("Result in getting user balances in trading login", result.data);

                                this.getSenderDetails().then(res => {
                                  if(this.debugConsole)  console.log("Get user profile in the trading login page", res);
                                
                                    if(res.data.status === 'closed') {
                                        this.$store.commit("UPDATE_USER_STATUS", res.data.status);
                                        // calling the logout api
                                        this.authLogout().then(res => {
                                            this.$store.commit("USER_LOGOUT");
                                        }).catch(err => {
                                            if (this.debugConsole) console.log("Error in logut the current logged in user", err);
                                        })
                                        setTimeout(() => {
                                            this.$store.commit("UPDATE_USER_STATUS");
                                        }, 8000);
                                        this.loginSpinner = false;
                                        return;
                                    }

                                  if(res.data.kyc_status === 'blocked') {
                                        this.$store.commit("UPDATE_USER_KYC", res.data.kyc_status);
                                        this.$store.commit("USER_LOGOUT");
                                        // calling the logout api
                                        this.authLogout().then(res => {
                                            this.$store.commit("USER_LOGOUT");
                                        }).catch(err => {
                                            if (this.debugConsole) console.log("Error in logut the current logged in user", err);
                                        })
                                        setTimeout(() => {
                                            this.$store.commit("UPDATE_USER_KYC");
                                        }, 8000);
                                        this.loginSpinner = false;
                                    } else {
                                        this.getAuthenteqUrl().then(res =>{
                                            if(this.debugConsole)  console.log("Get Authenteq URL after login", res.data);

                                            this.getReceivers().then(res => {
                                                if(this.debugConsole)    console.log("Get the receivers in the trading login page", res.data);


                                                //set "trading" as the type
                                                this.submitTransferType('trading');

                                                //only go to next screen if login successful and kyc status not blocked
                                                this.getUserKyc !== 'blocked' && this.$router.push('/trading/main');
                                            }).catch(err => {
                                                console.log("Error in getting the receivers in trading login page", err.response);
                                            })

                                        }).catch(err => {
                                            console.log("Error in getting Authenteq URL after login", err.response)
                                        })
                                    }

                                  


                                }).catch(err => {
                                  console.log("Error in getting user profile in trading login page",err.response)
                                })


                              }).catch(err => {
                                console.log("Err in getting user balances in trading login", err.response);
                              })



                            } else {
                                this.validationLoginError = true;
                            }


                        },
                    )
                        .catch(err => {
                            if(this.debugConsole) console.log("Error in Account Login Form", err.response);
                                // email not found
                                if(err.response.data.message === 'not_found')
                                {
                                    this.validationRegisteredEmailNotFound = true;
                                    this.validationPasswordLoginNotAuthorized = false;
                                    this.validationLoginError = false;
                                }
                                // password not found
                                else if(err.response.data.message === 'not_authorized')
                                {
                                    this.validationPasswordLoginNotAuthorized = true;
                                    this.validationRegisteredEmailNotFound = false;
                                    this.validationLoginError = false;
                                }
                                else {
                                    this.validationLoginError = true;
                                    this.validationPasswordLoginNotAuthorized = false;
                                    this.validationRegisteredEmailNotFound = false;
                                }

                            this.loginSpinner = false;
                        });
                }


            },
            goToTradingHomepage() {
                this.$router.push('/trading/main');
            },
            switchToForgotPassword()
            {
                this.forgotPassword  = true;
            },
            backToLoginForm(){
                this.forgotPassword = false;
                this.forgotPasswordEmail = "";
                this.resetPasswordSuccessful = false;
                this.validationForgotPasswordEmailError = false;

            },
            submitEmailToResetPassword (){

                //validation
                if(!this.forgotPasswordEmail || this.forgotPasswordEmail && this.forgotPasswordEmail.indexOf('@') < 2 )
                {
                    this.validationForgotPasswordEmailError = true;
                }
                else
                {
                    this.validationForgotPasswordEmailError = false;
                }

                if(this.validationForgotPasswordEmailError){
                    return;
                }
                this.resetPasswordSuccessful = false;

                let data = {}
                data.email = this.forgotPasswordEmail;

                this.resetPassword(data).then(res => {
                    if(this.debugConsole) console.log("Reset Password Result in login form", res);
                    if(res.data.message === "password_reset" || res.data.status === "OK")
                    {
                        this.resetPasswordSuccessful = true;
                    }
                }).catch(err => {
                    if(this.debugConsole)  console.log("Reset Password err in login form", err.response);
                    if(err.response.data.message === 'not_found')
                    {
                        this.validationForgotPasswordEmailError = true
                        this.resetPasswordSuccessful = false;
                    }

                })

            },
            backToLoginAfterResetPassword(){
                this.forgotPassword = false;
                this.resetPasswordSuccessful = false;
                this.forgotPasswordEmail = "";
            }

        }
    }
</script>
